import React from "react";
import { Container, Card } from "react-bootstrap";
import Slider from "react-slick";

import StarRating from "./star-rating";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class ReviewSlider extends React.Component {
  render() {
    let { reviews } = this.props;
    let slidesToShow = reviews.length > 3 ? 3 : reviews.length;
    const settings = {
      dots: true,
      speed: 500,
      slidesToShow: slidesToShow,
      slidesToScroll: 1,
      initialSlide: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: slidesToShow,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: slidesToShow,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Slider {...settings}>
        {reviews &&
          reviews.map((review, index) => {
            return (
              <Container key={index}>
                <Card>
                  <Card.Body>
                    <Card.Title>{review.quote}</Card.Title>
                    <StarRating stars={Number(review.stars)} color={`gold`} />
                    <Card.Text>
                      {review.author}, {review.date}
                    </Card.Text>
                    <Card.Text>
                      Source: <a href={review.url}>{review.name}</a>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Container>
            );
          })}
      </Slider>
    );
  }
}

export default ReviewSlider;

import React from "react";
import { graphql, Link } from "gatsby";
import { Container, Row, Col, Button, Card, Breadcrumb } from "react-bootstrap";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import Helmet from "gatsby-plugin-react-helmet";

import Layout from "../components/layout";
import PlanCards from "../components/plans-card";
import ReviewSlider from "../components/reviews-slider";
import GenericSlider from "../components/generic-slider";
import StickyHeader from "../components/sticky-header";
import { DisclaimerTooltip } from "../components/disclaimer-tooltip";
import StarRating from "../components/star-rating";
import SEO from "../components/seo";

import "./landing-page.module.scss";

function filterProducts(allProducts, integratedProducts) {
  return integratedProducts
    .map((product) => {
      return allProducts.filter((item) => {
        if (item.node.fields.slug === product) {
          return item.node;
        }
      });
    })
    .reduce((acc, val) => acc.concat(val), []);
}

function alternateProducts(allProducts, tags) {
  let alternates = [];
  for (let i = 0; i < allProducts.length; i++) {
    if (!allProducts[i].node.frontmatter.tags) {
      continue;
    }
    for (let j = 0; j < allProducts[i].node.frontmatter.tags.length; j++) {
      if (tags.includes(allProducts[i].node.frontmatter.tags[j])) {
        alternates.push(allProducts[i]);
        break;
      }
    }
  }
  return alternates;
}

class LandingPage extends React.Component {
  integration(allProducts, integrations) {
    if (integrations.length !== 0) {
      return (
        <>
          <hr></hr>
          <Row className={`mt-5`}>
            <Col xs="12" className={`mb-2`}>
              <h3>Integrations</h3>
            </Col>
            <Col xs="12" className="mb-5">
              <GenericSlider
                items={filterProducts(allProducts, integrations)}
              />
            </Col>
          </Row>
        </>
      );
    }
  }

  alternatives(alternatives) {
    if (alternatives.length !== 0) {
      return (
        <>
          <hr></hr>
          <Row className={`mt-5`}>
            <Col xs="12" className={`mb-2`}>
              <h3>Alternatives</h3>
            </Col>
            <Col xs="12" className="mb-5">
              <GenericSlider items={alternatives} />
            </Col>
          </Row>
          <hr></hr>
        </>
      );
    }
  }

  reviews(stars, aggregate, reviews, reviewTooltip) {
    if (reviews.length !== 0) {
      return (
        <>
          <hr></hr>
          <Row className={`mt-5`}>
            <Col xs={12}>
              <h3>Reviews</h3>
            </Col>
            <Col xs="12" className="mt-4 mb-5">
              <ReviewSlider reviews={reviews} />
            </Col>
            <Col className="text-right mb-4">
              <DisclaimerTooltip disclaimer={reviewTooltip} />
            </Col>
          </Row>
        </>
      );
    }
  }

  render() {
    let { frontmatter: data } = this.props.data.markdownRemark;
    let { edges: allProducts } = this.props.data.allProducts;
    let { catBreadcrumb } = this.props.data;
    let { reviewTooltip, earningsTooltip, bc } =
      this.props.data.settings.childMarkdownRemark.frontmatter;
    const button_color = data.offer.color ? data.offer.color : bc;
    let category = catBreadcrumb.edges.filter(
      (cat) => cat.node.frontmatter.slug === data.category[0],
    )[0].node.frontmatter;
    return (
      <Layout title={data.title}>
        <SEO
          description={data.meta_description || ""}
          keywords={data.keywords || []}
          title={data.title || ""}
        />
        {data.retrack && (
          <Helmet>
            <script
              type="text/javascript"
              src={`https://get.businesspro.co/trackcl.js?rtkcmpid=${data.redtrack}`}
            ></script>
          </Helmet>
        )}
        <StickyHeader data={data} earningsTooltip={earningsTooltip} bc={bc} />

        <Container>
          <Breadcrumb>
            <Link to="/">Home</Link>
            <p className="pr-2 pl-2">/</p>
            <Link to={`/categories/${category.slug}`}>{category.title}</Link>
            <p className="pr-2 pl-2">/</p>
            <Breadcrumb.Item active>{data.title}</Breadcrumb.Item>
          </Breadcrumb>
          <section id={`highlight`}>
            <Row className="mt-5">
              <Col xs="12" sm="6">
                <div
                  dangerouslySetInnerHTML={{ __html: data.description }}
                ></div>
                <p>{data.special_offer}</p>
                <Button
                  variant="success"
                  style={{
                    fontSize: "35px",
                    backgroundColor: button_color,
                    borderColor: button_color,
                  }}
                  className="col-12 p-3 mt-2 mb-4"
                  href={data.offer.url}
                >
                  {data.offer.text}
                </Button>
                <Col className={`mb-3`}>
                  <Row className="justify-content-center" noGutters>
                    Aggregate Rating = {`${Number(data.stars)}/5`}
                    <StarRating stars={Number(data.stars)} color={`gold`} />
                  </Row>
                  <Row className="justify-content-center" noGutters>
                    Based on {data.aggregate.total_reviews} reviews from{" "}
                    {data.aggregate.total_sources} sources
                  </Row>
                </Col>
                <Col className="text-center mt-3 mb-3">
                  <DisclaimerTooltip disclaimer={reviewTooltip} />
                </Col>
                <h3 className="mt-5 mb-3">{data.bestin_title}:</h3>
                <ul>
                  {data.bestin &&
                    data.bestin.map((category, index) => {
                      return (
                        <li className="highlight-category-item" key={index}>
                          <a href={`#categories`}>{category.name}</a>
                        </li>
                      );
                    })}
                </ul>
              </Col>
              <Col xs="12" sm="6">
                <Img
                  loading="eager"
                  fluid={data.image.childImageSharp.fluid}
                  placeholderStyle={{ visibility: "hidden" }}
                />
              </Col>
              <Col xs="12" className="mt-4 mb-4">
                <Card bg="light">
                  <Card.Body>
                    <Card.Title>{data.company.name}</Card.Title>
                    <Card.Text>{data.company.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </section>

          <section id={`plans-pricing`}>
            <hr></hr>
            <Row className="mt-5">
              <Col xs="12">
                <h4>Plans and Pricing</h4>
              </Col>
              <Col xs="12" className="mb-4">
                <PlanCards plans={data.plans} offer={data.offer} bc={bc} />
              </Col>
            </Row>
            <div id={`categories`}></div>
          </section>

          <section id={`categories`}>
            <hr></hr>
            <Row className="mt-5">
              <Col xs="12" className="mb-4">
                <h4>Categories</h4>
              </Col>
              {data.bestin &&
                data.bestin.map((category, index) => {
                  return (
                    <Col xs="12" className={`mb-4`} key={index}>
                      <Card>
                        <Card.Body>
                          <Row>
                            <Col xs="12" sm="3">
                              <Card.Title>{category.name}</Card.Title>
                              <i
                                className={`material-icons mr-auto ml-auto`}
                                style={{ fontSize: "60px" }}
                              >
                                {category.icon}
                              </i>
                            </Col>
                            <Col xs="12" sm="3">
                              <ul>
                                {category.highlights &&
                                  category.highlights.map(
                                    (highlight, index) => {
                                      return (
                                        <li key={index}>{highlight.point}</li>
                                      );
                                    },
                                  )}
                              </ul>
                            </Col>
                            <Col xs="12" sm="6">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: category.description,
                                }}
                              ></div>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}
            </Row>
          </section>

          <section id={`reviews`}>
            {data.reviews &&
              this.reviews(
                data.stars,
                data.aggregate,
                data.reviews,
                reviewTooltip,
              )}
          </section>

          <section id={`integration`}>
            {data.integrations &&
              this.integration(allProducts, data.integrations)}
          </section>

          <section id={`cta`}>
            <hr></hr>
            <Row className={`mt-5`}>
              <Col xs={12}>
                <Card bg="light" className="text-center">
                  <Card.Body>
                    <Card.Title>
                      <h2>{data.title}</h2>
                    </Card.Title>
                    <Button
                      variant="success"
                      style={{
                        fontSize: "30px",
                        backgroundColor: button_color,
                        borderColor: button_color,
                      }}
                      className="pt-4 pb-4 pl-5 pr-5"
                      href={data.offer.url}
                    >
                      {data.offer.text}
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </section>

          <section id={`alternatives`}>
            {data.tags &&
              this.alternatives(alternateProducts(allProducts, data.tags))}
          </section>
        </Container>
      </Layout>
    );
  }
}

LandingPage.propTypes = {
  data: PropTypes.object,
};

export default LandingPage;

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        meta_description
        keywords
        redtrack
        category
        integrations
        template
        description
        logo {
          childImageSharp {
            fixed(width: 125) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        sub_heading
        title
        bestin_title
        bestin {
          description
          highlights {
            point
          }
          icon
          name
        }
        company {
          description
          name
        }
        image {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        offer {
          text
          url
          color
        }
        plans {
          cost
          name
          text
          url
          details {
            point
          }
        }
        stars
        aggregate {
          total_reviews
          total_sources
        }
        reviews {
          author
          date
          name
          quote
          stars
          url
        }
        special_offer
        tags
      }
    }
    allProducts: allMarkdownRemark(
      filter: {
        frontmatter: { template: { eq: "product" } }
        fields: { slug: { ne: $slug } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            slug
            logo {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            title
            tags
          }
        }
      }
    }
    settings: file(name: { eq: "global" }) {
      childMarkdownRemark {
        frontmatter {
          title
          bc
          earningsTooltip {
            name
            text
          }
          reviewTooltip {
            name
            text
          }
        }
      }
    }
    catBreadcrumb: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "category" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
          }
        }
      }
    }
  }
`;

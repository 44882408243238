import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class PlanCards extends React.Component {
  render() {
    let { plans, offer, bc } = this.props;
    const button_color = offer.color ? offer.color : bc;
    return (
      <>
        {plans &&
          plans.map((plan, index) => {
            return (
              <Card className="mt-4" key={index}>
                <Card.Body>
                  <Row>
                    <Col xs="12" sm="3">
                      <Card.Title>{plan.name}</Card.Title>
                      <Card.Title>{plan.cost}</Card.Title>
                    </Col>
                    <Col xs="12" sm="6">
                      <Card.Title>Plan Details</Card.Title>
                      <ul>
                        {plan.details &&
                          plan.details.map((detail, index) => {
                            return <li key={index}>{detail.point}</li>;
                          })}
                      </ul>
                    </Col>
                    <Col xs="12" sm="3">
                      <Button
                        className="col-12"
                        variant="success"
                        style={{
                          backgroundColor: button_color,
                          borderColor: button_color,
                        }}
                        size="lg"
                        href={plan.url}
                      >
                        {plan.text}
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            );
          })}
      </>
    );
  }
}

export default PlanCards;

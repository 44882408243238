import React from "react";
import { Container, Card } from "react-bootstrap";
import Slider from "react-slick";
import Img from "gatsby-image";
import { Link } from "gatsby";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class GenericSlider extends React.Component {
  render() {
    let { items } = this.props;
    let slidesToShow = items.length > 3 ? 3 : items.length;
    const settings = {
      dots: true,
      speed: 500,
      slidesToShow: slidesToShow,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: slidesToShow,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: slidesToShow,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Slider {...settings}>
        {items &&
          items.map((item, index) => {
            return (
              <Link
                key={index}
                to={`/${item.node.fields.slug}`}
                style={{ textDecoration: "none" }}
              >
                <Container className="text-center">
                  <Card>
                    <Card.Body className={`ml-auto mr-auto`}>
                      <Card.Title>{item.node.frontmatter.title}</Card.Title>
                      <Img
                        loading="eager"
                        fluid={item.node.frontmatter.logo.childImageSharp.fluid}
                        placeholderStyle={{ visibility: "hidden" }}
                      />
                    </Card.Body>
                  </Card>
                </Container>
              </Link>
            );
          })}
      </Slider>
    );
  }
}

export default GenericSlider;

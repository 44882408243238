import React from "react";
import { StickyContainer, Sticky } from "react-sticky";
import { Container, Row, Col, Button } from "react-bootstrap";
import Img from "gatsby-image";

import StarRating from "./star-rating";
import { DisclaimerTooltip } from "./disclaimer-tooltip";

class StickyHeader extends React.Component {
  constructor() {
    super();
    this.state = {
      deviceWidth: 0,
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      this.setState({ deviceWidth: window.innerWidth });
    }
  }

  render() {
    const { data, earningsTooltip, bc } = this.props;
    const button_color = data.offer.color ? data.offer.color : bc;
    const isSmallScreen = this.state.deviceWidth < 800;
    if (isSmallScreen) {
      return (
        <section
          className={`intro`}
          style={{ backgroundColor: "#fafafa", padding: "10px" }}
        >
          <Container>
            <Row>
              <Col xs="12" sm="6" className="mt-auto mb-auto">
                <Img
                  loading="eager"
                  fixed={data.logo.childImageSharp.fixed}
                  placeholderStyle={{ visibility: "hidden" }}
                />
              </Col>
              <Col xs="12" sm="6" className="mt-auto mb-auto">
                <Row noGutters>
                  <h4>{data.title}</h4>
                </Row>
                <Row noGutters>
                  <StarRating stars={Number(data.stars)} color={`gold`} />
                </Row>
              </Col>
              <Col xs="12" sm="6" className="mt-auto mb-auto pt-4 pb-4">
                <h6>{data.sub_heading}</h6>
              </Col>
              <Col xs="12" sm="6" className="mt-auto mb-auto">
                <Button
                  variant="success"
                  className="col-12 mb-2 p-3"
                  style={{
                    backgroundColor: button_color,
                    borderColor: button_color,
                  }}
                  href={data.offer.url}
                >
                  {data.offer.text}
                </Button>
                <div className="text-right">
                  <DisclaimerTooltip disclaimer={earningsTooltip} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      );
    } else {
      return (
        <StickyContainer>
          <Sticky>
            {({ style }) => (
              <section
                className={`intro`}
                style={{
                  ...style,
                  backgroundColor: "#fafafa",
                  padding: "0px",
                  borderBottom: "1px solid #e2dcdc",
                  boxShadow: "0 0 0.25em #dae1e4",
                }}
              >
                <Container>
                  <Row noGutters>
                    <Col xs="12" sm="2" className="mt-auto mb-auto">
                      <Img
                        loading="eager"
                        fixed={data.logo.childImageSharp.fixed}
                        placeholderStyle={{ visibility: "hidden" }}
                      />
                    </Col>
                    <Col xs="12" sm="7" className="mt-auto mb-auto">
                      <Row className="align-items-center" noGutters>
                        <h1 className="mr-3">{data.title}</h1>
                        <StarRating stars={Number(data.stars)} color={`gold`} />
                      </Row>
                      <p className="m-0">{data.sub_heading}</p>
                    </Col>
                    <Col xs="12" sm="3" className="mt-auto mb-auto">
                      <Col className="text-right">
                        <Button
                          variant="success"
                          size="lg"
                          className=" mb-2"
                          style={{
                            backgroundColor: button_color,
                            borderColor: button_color,
                          }}
                          href={data.offer.url}
                        >
                          {data.offer.text}
                        </Button>
                      </Col>
                      <Col className="text-right">
                        <DisclaimerTooltip
                          className=""
                          disclaimer={earningsTooltip}
                        />
                      </Col>
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          </Sticky>
        </StickyContainer>
      );
    }
  }
}

export default StickyHeader;
